.ng-select .ng-select-container {
  border-color: #ebedf2;
}

.is--cursor-pointer:hover {
  cursor: pointer;
}

.cb-soldmodal__line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 0 20px 0 0;
}

.hc-multiselect .selected-list .c-btn {
  padding: 0.65rem 1.25rem !important;
  box-shadow: none !important;
  line-height: normal;
  border: 1px solid #ebedf2 !important;
}

.hc-multiselect .selected-list .c-list .c-token {
  line-height: normal;
}

.hc-multiselect .selected-list .c-list .c-token .c-label {
  font-size: 12px;
  text-transform: lowercase;
}

.list-area {
  box-shadow: none !important;
}

.modal--check .modal-dialog {
  max-width: 380px;
  margin: 30px auto;
  position: relative;
}

.modal--check .modal-dialog::after,
.modal--check .modal-dialog::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 14px;
  left: 0;
  bottom: -14px;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAOCAYAAAA8E3wEAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABjSURBVHgBvc1RDYAwDEXRJwUJSKoEJNRpJSChsAT4YFnWbW1vcr8PVJU1J7nfUEpA5cPeAlGpsED0bGIBaMF2WHJA7ZgDOo4tooSVBlGCR0aU4FkHPRBRA2VE9kMZGT0oY6ILhwQzghbExowAAAAASUVORK5CYII=');
  background-repeat: repeat-x;
}

.modal--check .modal-dialog::before {
  bottom: initial;
  top: -14px;
  transform: rotate(180deg);
}

.modal--check .modal-content {
  border-radius: 0;
  padding: 15px 10px;
  box-shadow: none;
}

@media print {
  html {
    height: auto !important;
  }

  @page {
    size: auto;
    margin: 0;
  }

  html, body {
    background: transparent !important;
    box-shadow: none !important;
    text-shadow: none !important;
    position: static !important;
    color: #000 !important;
    font-family: arial, sans-serif;
  }

  body {
    width: 76mm;
    margin: 0;
    display: block;
  }

  body * {
    font-size: 11px !important;
    background: transparent !important;
  }

  thead {
    display: table-header-group;
  }

  tr, img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  .m-aside-left, .m-footer, .m-header, .m-subheader, .m-content, #toast-container, .modal {
    display: none !important;
  }

  .m-body, .m-header--fixed .m-body, .m-body .m-wrapper, .m-page {
    margin: 0 !important;
    padding: 0 !important;
    display: initial !important;
    flex: none !important;
  }

  app-home {
    visibility: hidden !important;
  }
}

.has--spin {
  vertical-align: middle;
  line-height: .9;
  animation: m-loader-rotate 0.6s linear infinite;
}

.hc-main-content {
  width: 100%;
  word-break: break-word;
}

.m-datatable__table {
  word-break: initial;
}

@media (min-width: 768px) {
  .hc-main-content {
    margin-bottom: 60px;
  }
}

/* hide arrows in firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* new modal styles */
.modal-header--brand {
  border: 0;
}

.modal .modal-content .modal-header--brand .modal-title {
  color: #0788FF;
  font-size: 30px;
  font-weight: 600;
}

.modal .modal-content .modal-header--brand .close {
  color: #0788FF;
  opacity: 1;
}

.modal-footer--brand {
  padding-right: 0;
  padding-left: 0;
  border-top: 0;
  justify-content: space-between;
}

.hc-button {
  font-size: 15px;
  font-weight: 500;
  border-radius: 5px;
  padding: 6px 17px;
  border: 0;
  height: 40px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.15);
}

.hc-button + .hc-button {
  margin-left: 10px;
}

.hc-button:hover {
  cursor: pointer;
}

.hc-button:disabled {
  background: #F2F3F8;
  color: #B3B3B3;
  border-color: #E0E0E0;
}

.hc-button--primary {
  color: #fff;
  background: #0788FF;
}

.hc-button--success {
  background: #D5FFF0;
  color: #00C67F;
  padding: 8px 20px 7px;
  font-size: 14px;
  border: 2px solid #00C67F;
}

.hc-button--secondary {
  background: #D0E9FF;
  color: #0788FF;
  padding: 8px 20px 7px;
  font-size: 14px;
  border: 2px solid #0788FF;
}

.hc-button--danger {
  color: #F84445;
  background: #FFD7D8;
  border: 2px solid #F84445;
}

.hr {
  border-top-color: #E5E5E5;
  margin-top: 20px;
  margin-bottom: 20px;
}

.hc-title {
  font-weight: 500;
  font-size: 30px;
  line-height: normal;
  color: #0788FF;
}

.hc-title--small {
  font-weight: 600;
  font-size: 22px;
}

/* Happy cake table style */
.hc-table {
  width: 100%;
  border-collapse: collapse;
}

.hc-table__cell {
  padding: 7px 10px;
}

.hc-table__row:not(.hc-table__row--head):hover {
  background-color: #E0E0E0;
  cursor: pointer;
}

.hc-table__row--head {
  border-bottom: 1px solid #E0E0E0;
}

.product-wrap {
  display: flex;
  flex-wrap: wrap;
}

.product-wrap__item {
  width: 100%;
  min-width: 230px;
}

@media (min-width: 576px) {
  .product-wrap__item {
    width: 50%;
  }
}

@media (min-width: 767px) {
  .product-wrap__item {
    width: 33%;
  }
}

@media (min-width: 992px) {
  .product-wrap__item {
    width: 20%;
  }
}

.hc-icon {
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-image: url('/assets/images/sprite.svg');
}

.is--remove {
  position: absolute;
  right: 10px;
  top: 6px;
}

.modal-dialog.modal-x-full {
  min-width: 100%;
  height: 100%;
  margin: 0;
  border-radius: 0;
}

.modal-x-full .modal-content {
  border-radius: 0;
  min-height: 100%;
}

.modal-x-full .modal-body {
  min-height: 77vh;
}

.m--text-line-through {
  text-decoration: line-through;
}
