.hx-filters {
  display: flex;
  flex-wrap: wrap;
}

.hx-filters div.filter {
  margin-right: 1rem;
}

.ng-select.auto-grow {
  display: flex;
}

.ng-select .ng-select-container {
  min-width: 12rem;
  width: auto;
}

.ng-select .ng-dropdown-panel {
  min-width: 100%;
  width: auto;
}

hx-checkbox {
  display: block;
}
